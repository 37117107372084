// import * as ort from 'onnxruntime-web';
import React from 'react';
import LocalizedTextEditor from '../../components/LocalizedTextEditor';
import { Box, Button, Card, Grid, Typography } from '@mui/material';

const NER_CLASSES = [
    "Osoba",
    "Data",
    "Organizacja",
    "Adres",
    "Kwota",
    "Numer",
    "Strona internetowa",
    "Adres e-mail",
]



const Anonymizer = () => {
  const [text, setText] = React.useState('');
  const [anonymizedText, setAnonymizedText] = React.useState('');

  const handleTextChange = (text: string) => {
    setText(text);
  };

  // const handleAnonymize = async () => {
  //   const session = await ort.InferenceSession.create('/static/gliner_multi_pii-splitv1-2024-08-29-3_checkpoint-400.onnx');
  //   const input = new ort.Tensor('string', [text]);
  //   const nerClasses = new ort.Tensor('string', NER_CLASSES);
  //   const threshold = new ort.Tensor('float32', [0.3]);
  //   const result = await session.run({ input: input, ner_classes: nerClasses, threshold: threshold });
  //   // Process the result and update anonymizedText
  //   // This part needs to be implemented based on the model's output structure
  //   setAnonymizedText(JSON.stringify(result));
  // };
  const handleAnonymize = () => {
    console.log('Anonimizuj');
  };

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Card className="flat-card" sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>Edytor</Typography>
            <LocalizedTextEditor markdown={text} onChange={handleTextChange} />
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card className="flat-card" sx={{ p: 2, position: 'sticky', bottom: 0, backgroundColor: 'white', borderTop: '1px solid #e0e0e0' }}>
            <Typography variant="h6" gutterBottom>Wyniki anonimizacji</Typography>
            <Typography variant="body1">{anonymizedText}</Typography>
            <Button onClick={handleAnonymize}>Anonimizuj</Button>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Anonymizer;