import ArticleIcon from "@mui/icons-material/Article";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import MusicNote from "@mui/icons-material/MusicNote";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import LockIcon from "@mui/icons-material/Lock";
import EditIcon from "@mui/icons-material/Edit";
import {
  Card,
  Box,
  Stack,
  Grid,
  Typography,
  LinearProgress,
  Chip,
  Tooltip,
} from "@mui/material";
import Button from "../../components/generic/Button";
import * as React from "react";
import LoadingOverlay from "react-loading-overlay-ts";
import { SnackbarContext } from "../../contexts/SnackbarContext";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import getCSRFToken from "../../stores/CSRFStore";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { useDropzone } from 'react-dropzone';
import DataUsageNoticeModal from "../../components/dataUsageNoticeModal";
import useUserData from "../../hooks/useUserData";
import TextStrings from "./Strings";
import { Colors } from "legalgpt-react-shared";

const MAX_DOCUMENTS_AMOUNT = 50;

interface DocumentProps {
  type: string;
  name: string;
  fileDate: string;
  fullPath: string;
  fileEncrypted: boolean;
  refresh: Function;
  status: string;
  finished: boolean;
  length: number;
  callback?: Function;
}

interface IconProps {
  [key: string]: JSX.Element | (string | JSX.Element)[] | undefined;
}

const ICONS: IconProps = {
  pdf: <PictureAsPdfIcon />,
  docx: <ArticleIcon />,
  txt: <ArticleIcon />,
  mp3: <MusicNote />,
  wav: <MusicNote />,
  md: <ArticleIcon />,
  more: <MoreHorizIcon />,
  edit: <EditIcon />,
  delete: <DeleteOutlineIcon fontSize="small" />,
  lock: <LockIcon fontSize="small" />,
  unlock: <LockOpenIcon fontSize="small" />,
};

const statusMapping = (status: string) => {
  // x_y_0 -> x_y
  const percentage = status.split("_").pop();
  status = status.replace(/_\d+$/, "");

  switch (status) {
    case "queue":
      return "W kolejce";
    case "reading_file":
      return "Czytanie pliku";
    case "parsing":
      return `Czytanie pliku: ${percentage}%`;
    case "vectorizing":
      return `Przetwarzanie: ${percentage}%`;
    default:
      return "Nieznany";
  }
};

const Document = ({
  type,
  name,
  fileDate,
  fullPath,
  fileEncrypted,
  refresh,
  status,
  finished,
  length,
  callback,
}: DocumentProps) => {
  const snackbar = React.useContext(SnackbarContext);
  const navigate = useNavigate();
  const axios = useAxiosPrivate();
  const { userData } = useUserData();

  function deleteDocument() {
    getCSRFToken();
    axios
      .delete(`api/v1/delete-document?path=${fullPath}`)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        console.log(data);
        refresh();
        snackbar.setMessage("Dokument został usunięty");
        snackbar.setSeverity("success");
        snackbar.setOpen(true);
      })
      .catch((error) => {
        console.log(error);
        snackbar.setMessage("Wystąpił błąd podczas usuwania dokumentu");
        snackbar.setSeverity("error");
        snackbar.setOpen(true);
      });
  }

  const goToDocEditor = (path: string) => {
    // get the file content from the backend
    axios.get(`api/v1/document?path=${path}&full=true`).then((response) => {
      const fileContent = response.data.content;
      navigate(`/doc-editor`, { state: { initialText: fileContent, title: name } });
    });
  }

  return (
    <Card className="p-3 my-1 shadow-0 border" sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          margin: 0.5,
        }}
      >
        <Stack
          direction="column"
          width={"100%"}
          justifyContent={"space-between"}
        >
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "baseline",
                marginRight: "16px",
                color: "#FA6E68",
              }}
            >
              {ICONS[type]}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  fontWeight: "bold",
                  wordBreak: "break-all",
                  maxHeight: 50,
                  textWrap: "wrap",
                  overflow: "auto",
                }}
              >
                {finished ? (
                  <Link
                    to={`/show-document?path=${fullPath}`}
                    target="_blank"
                  >
                    {name}
                  </Link>
                ) : (
                  name
                )}
              </Box>
            </Box>
          </Box>
        </Stack>
        <Stack
          direction={"column"}
          width={"100%"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          {fileEncrypted && (
            <Tooltip title={TextStrings.document.encrypted_tooltip}>
              <Chip
                label={"ZASZYFROWANY"}
                size="small"
                sx={{
                  width: "fit-content",
                  backgroundColor: "#0e2451",
                  color: "white",
                }}
              />
            </Tooltip>
          )}
          <div>{length} słów</div>
        </Stack>
        <Stack
          direction={"column"}
          width={"100%"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Box style={{ padding: 0, margin: 0, color: "text.secondary" }}>
            {new Date(fileDate).toLocaleDateString("pl-PL")} r.
          </Box>
          <Box style={{ padding: 0, margin: 0, color: "text.secondary" }}>
            {new Date(fileDate).toLocaleTimeString("pl-PL")}
          </Box>
        </Stack>
        <Stack
          direction={"row"}
          width={"100%"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          {finished ? (
            <Button
              sx={{ marginLeft: "8px", marginRight: "10px" }}
              variant="contained-dark"
              onClick={() => {
                if (callback) {
                  callback(fullPath);
                } else {
                  navigate(`/contract-analysis?filePath=${fullPath}`);
                }
              }}
            >
              {(callback && "Wybierz") || "Przeanalizuj"}
            </Button>
          ) : (
            <Button
              id={"status-button"}
              sx={{ marginLeft: "8px", marginRight: "10px" }}
              variant="contained-dark"
              disabled
            >
              {statusMapping(status)}
            </Button>
          )}
          {
            finished && userData.featureFlags.includes("doc_editor") && (
              <Button
                id={"edit-button"}
                variant="outlined-light"
                onClick={() => {
                  goToDocEditor(fullPath);
                }}
                color="white"
                sx={{
                  color: "#99B2C6",
                  borderColor: "#99B2C6",
                  minWidth: "unset",
                  display: "flex",
                  float: "right",
                  width: 50,
                }}
              >
                {ICONS["edit"]}
              </Button>
            )
          }
          <Button
            id={"delete-button"}
            variant="outlined-light"
            onClick={deleteDocument}
            color="white"
            sx={{
              color: "#99B2C6",
              borderColor: "#99B2C6",
              minWidth: "unset",
              display: "flex",
              float: "right",
              width: 50,
            }}
          >
            {ICONS["delete"]}
          </Button>
        </Stack>
      </Box>
    </Card>
  );
};

export const UploadDocument = ({
  collection,
  refresh,
  filesAmount,
}: {
  collection: string;
  refresh: Function;
  filesAmount: number;
}) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop: files => setFile(files.length ? files[0] || null : null),
    accept: {
      "image/*": [".png", ".gif", ".jpeg", ".jpg", ".bmp"],
      "application/*": [".pdf"],
      // This is required to show *.pdf files in some chrome versions
      "application/pdf": [".pdf"],
      "text/plain": [".txt"],
      "audio/*": [".mp3", ".wav", ".m4a", ".flac", ".ac3"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
    },
  });
  const [file, setFile] = React.useState<File | null>(null);
  const [loading, setLoading] = React.useState(false);
  const snackbar = React.useContext(SnackbarContext);
  const axios = useAxiosPrivate();
  const [showDataUsageNotice, setShowDataUsageNotice] = React.useState<boolean>(false);
  const { userData } = useUserData();
  getCSRFToken();

  function uploadDocument() {
    if (!!!userData.dataUsageNotice) {
      setShowDataUsageNotice(true);
      return;
    }

    if (filesAmount >= MAX_DOCUMENTS_AMOUNT) {
      snackbar.setMessage(
        "Możliwość wysłania maksymalnie 10 dokumentów. Usuń któryś z dokumentów jeśli chcesz wysłać kolejny"
      );
      snackbar.setDuration(10000);
      snackbar.setSeverity("error");
      snackbar.setOpen(true);
      return;
    }

    if (file) {
      setLoading(true);

      const formData = new FormData();
      formData.append("file", file);
      formData.append("path", collection);
      axios
        .post(`api/v1/vectorize`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((data) => {
          console.log(data);
          refresh();
          setLoading(false);
          setFile(null);
          snackbar.setMessage("Plik został wysłany");
          snackbar.setSeverity("success");
          snackbar.setOpen(true);
        })
        .catch((error) => {
          console.log(error);
          snackbar.setMessage(error.message);
          snackbar.setSeverity("error");
          snackbar.setOpen(true);
          setLoading(false);
        });
    }
  }

  return (
    <div id={"upload-document"}>
      <LoadingOverlay
        active={loading}
        spinner={<LinearProgress />}
        text={<div style={{ fontSize: "16px" }}>Wysyłanie pliku...</div>}
        className="loading-overlay"
      >
        <Box
          id={"file-upload-box"}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            margin: "16px 0",
            border: "1px dashed gray",
          }}
        >
          {!loading && (
            <div style={{ width: "100%" }}>
              <Box
                className="w-100 h-100"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Stack
                  id={"file-upload-stack"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  direction={{
                    sx: "column",
                    md: "row",
                  }}
                >
                  <section className="drag-n-drop-container">
                    <div {...getRootProps({ className: "drag-n-drop-dropzone" })}>
                      <input {...getInputProps()} />
                      <div id={"file-upload-label"}>
                        <UploadFileIcon />
                        Wybierz plik
                      </div>
                    </div>
                  </section>
                </Stack>
              </Box>
            </div>
          )}
        </Box>
        <Box sx={{ margin: "16px", wordBreak: "break-all" }}>{file?.name}</Box>
        <Button
          variant="contained-dark"
          onClick={uploadDocument}
          width={"100%"}
        >
          Wyślij
        </Button>
      </LoadingOverlay>
      {showDataUsageNotice && <DataUsageNoticeModal />}
    </div>
  );
};

const DocumentBrowser = ({ collection, minimal, callback }: { collection: string, minimal?: boolean, callback?: Function }) => {
  const [files, setFiles] = React.useState([]);

  const { userData } = useUserData();
  const axios = useAxiosPrivate();
  const navigate = useNavigate();
  function refresh() {
    // getCSRFToken();
    axios
      .get(`api/v1/list-documents?path=${collection}`)
      .then((response) => {
        console.log(response.data);
        setFiles(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  React.useEffect(() => {
    refresh();

    const interval = setInterval(() => {
      refresh();
    }, 5000);

    return () => clearInterval(interval);
  }, [collection]);

  return (
    <>
      {!minimal &&
        <UploadDocument
          collection={collection}
          refresh={refresh}
          filesAmount={files.length}
        />
      }
      <Grid container mt={6} mb={2}>
        <Grid item xs={12} lg={6} width={"fit-content"} >
          <h1 className="mb-3">Twoja baza dokumentów</h1>
          <Typography variant="h3" sx={{ fontSize: "0.9rem" }}>
            Wysłane pliki pojawią się poniżej
          </Typography>
        </Grid>
        <Grid item xs={12} lg={6} width={"fit-content"} style={{ float: "right", padding: 10, borderRadius: "0.75rem" }}>
          <Stack direction={"row"} spacing={2} justifyContent={"flex-end"}>
            <Tooltip
              title={`Możliwość wysłania maksymalnie ${MAX_DOCUMENTS_AMOUNT} dokumentów`}
            >
              <Grid
                item
                width={"fit-content"}
                style={{
                  backgroundColor:
                    files.length >= MAX_DOCUMENTS_AMOUNT ? "#FA6E68" : Colors.White,
                  color: files.length >= MAX_DOCUMENTS_AMOUNT ? "white" : "black",
                  padding: 12,
                  borderRadius: "0.75rem",
                  border: `1px solid ${files.length >= MAX_DOCUMENTS_AMOUNT ? "#FA6E68" : Colors.GaiusLexBlue}`,
                  marginTop: "auto",
                  marginBottom: "auto"
                }}
              >
                Liczba dokumentów:{" "}
                <strong>
                  {files.length} / {MAX_DOCUMENTS_AMOUNT}
                </strong>
              </Grid>
            </Tooltip>
            {
              userData.featureFlags.includes("doc_editor") && (
                <Button variant="contained-dark" onClick={() => navigate("/doc-editor")}>Utwórz nowy dokument</Button>
              )
            }
          </Stack>
        </Grid>
      </Grid>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "left",
          border: "1px solid #f2f2f2",
          borderRadius: "8px",
          padding: "32px",
        }}
      >
        {files.length > 0 &&
          files.map((file: any, index: any) => {
            const fileType: string = file.path.split(".").pop() || "directory";
            const fileName: string = file.path.split("/").pop();
            const status = file.status;
            const finished = file.finished;
            // round length to hundreds/tens
            const length =
              file.length > 100
                ? Math.round(file.length / 100) * 100
                : Math.round(file.length / 10) * 10;

            return (
              <Document
                key={index}
                type={fileType}
                name={fileName}
                fullPath={file.path}
                fileDate={file.added}
                fileEncrypted={file.encrypted}
                refresh={refresh}
                status={status}
                finished={finished}
                length={length}
                callback={callback}
              />
            );
          })}
      </Box>
    </>
  );
};

export default DocumentBrowser;
