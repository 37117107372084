import React from "react";

import { Autocomplete, Box, Card, Grid, Stack, TextField, Tooltip, Typography, Dialog, Accordion, AccordionSummary, AccordionDetails, SnackbarContent } from "@mui/material";
import Button from "../../components/generic/Button";
import { Message, MessageProps } from "../caseLawSearch/Message";
import useGaiusWebSocket from "../../hooks/useGaiusWebSocket";
import { Formik } from "formik";
import { Helmet } from "react-helmet";
import LinearDeterminate from "../../components/LinearBuffer";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import getCSRFToken from "../../stores/CSRFStore";
import { Link } from "react-router-dom";
import { SnackbarContext } from "../../contexts/SnackbarContext";
import "./index.scss";
const GaiusLexLogo = require("~/public/static/img/gaius-lex-logo.svg");


const dissatisfied = require("~/public/static/img/dissatisfied.png");
const satisfied = require("~/public/static/img/satisfied.png");
const search = require("~/public/static/img/sprawa_karna.webp");
const pearls = require("~/public/static/img/pearls.webp");
const cars = require("~/public/static/img/cars.png");

const SelectDocumentButton = ({ callback, limit, orientation }: { callback: any, limit?: number, orientation?: "horizontal" | "vertical" }) => {
  const LIMIT = limit || 70000;
  const orient = orientation || "horizontal";

  const [previousDocument, setPreviousDocument] = React.useState<any>(null);
  const [documents, setDocuments] = React.useState<any>([]);
  const [chosenDocumentPath, setChosenDocumentPath] = React.useState<any>(null);
  const [currentLength, setCurrentLength] = React.useState<number>(0);

  const axios = useAxiosPrivate();

  const getDocuments = async () => {
    const response = await axios.get(`/api/v1/list-documents?path=test-collection`)

    setDocuments(response.data);
  }

  const getDocumentContent = async (path: Array<any>) => {
    const documents = [];

    for (let i = 0; i < path.length; i++) {
      const name = path[i].path.split("/").pop();
      const response = await axios.get(`/api/v1/document?page=0&path=${path[i].path}`);
      documents.push({
        name: name,
        content: response.data.content
      });
    }

    callback(documents);
    setPreviousDocument(path);
  }

  React.useEffect(() => {
    getDocuments();
  }, []);

  return (
    <div className={"chat__documents"}>
      <Stack direction={orient === "horizontal" ? "row" : "column"} spacing={2} className="w-100" justifyContent={orient === "horizontal" ? "end" : "center"}>
        <Autocomplete
          id="combo-box-demo"
          disablePortal
          onChange={(event, newValue: any) => {
            setChosenDocumentPath(newValue);
            setCurrentLength(newValue.reduce((acc: number, val: any) => acc + val.length, 0));
          }}
          getOptionDisabled={(option) => (option as any).length > LIMIT - currentLength || (option as any).length === 0}
          options={documents?.filter ? documents?.filter((document: { private: boolean }) => !document.private) : documents}
          getOptionLabel={(option) => (option as any).path?.split("/").pop()}
          style={{ width: "100%", maxWidth: "400px" }}
          renderInput={(params) => <TextField {...params} label={`Wybierz dokumenty (${currentLength}/${LIMIT})`} />}
          multiple
        />
        <Button
          disabled={!chosenDocumentPath || chosenDocumentPath === previousDocument || LIMIT - currentLength < 0}
          variant={"contained-dark"}
          onClick={() => {
            if (chosenDocumentPath) {
              getDocumentContent(chosenDocumentPath);
            }
          }}>
          {chosenDocumentPath === previousDocument ? "Dokumenty już dodane" : "Dodaj dokumenty"}
        </Button>
      </Stack>
    </div>
  );
}


const ChatWithGPT = () => {
  const [conversation, setConversation] = React.useState<MessageProps[]>([]);
  const [newestMessage, setNewestMessage] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [documents, setDocuments] = React.useState<any>([]);
  const [executing, setExecuting] = React.useState<boolean>(false);
  const [referredDocument, setReferredDocument] = React.useState<Array<any>>([]);
  const snackbarContext = React.useContext(SnackbarContext);
  const [introduction, setIntroduction] = React.useState<string>("");
  const [availableSources, setAvailableSources] = React.useState<any>({});
  const [selectedSources, setSelectedSources] = React.useState<any>([]);

  const axios = useAxiosPrivate();


  const ref = React.useRef<any>(null);

  const { sendMessage, lastMessage, getWebSocket } = useGaiusWebSocket("/ws/v1/chat-convo");
  const searchWS = useGaiusWebSocket("/ws/v1/query-judiciary");

  function getDocumentMessage() {
    if (referredDocument.length > 0) {
      let text = "";
      referredDocument.map((doc: any) => {
        text += doc.name + "\n----\n";
        text += doc.content;
        text += "\n";
      });

      const msg = {
        "role": "human",
        "message": "Oto dokumenty, do których powinieneś się odnosić podczas udzielania mi odpowiedzi oraz pisania pisma:\n" + text,
        "type": "document",
        "hidden": true
      }
      return msg;
    }
    return null;
  }

  function getIntroduction() {
    if (introduction) {
      const msg = {
        "role": "human",
        "message": `Cześć! Jestem ${introduction}. Jeżeli będziesz tworzyć dla mnie pisma, używaj moich danych.`,
        "type": "conversation",
        "hidden": true
      }
      return msg;
    }
    return null;
  }

  // Issue fix for copying text from the chat
  React.useEffect(() => {
    function copyListener(e: ClipboardEvent) {
      const text_only = document.getSelection()?.toString();
      const clipdata = e.clipboardData;

      if (clipdata && text_only) {
        //remove formatting, but keep line breaks
        clipdata.setData('text/plain', text_only);
        clipdata.setData('text/html', text_only);

        e.preventDefault();
      }
    }

    document.addEventListener('copy', copyListener);
    return () => {
      document.removeEventListener('copy', copyListener);
    }
  }, []);

  const cancelExecution = () => {
    getWebSocket()?.close();

    setExecuting(false);
    setLoading(false);
    document.title = document.title.replace("⌛ ", "");

    setNewestMessage("");
    if (lastMessage) {
      const data = JSON.parse(lastMessage.data);
      if (data.answer) {
        const msg = {
          "role": "gaius",
          "message": data.answer,
          "type": "conversation"
        }
        setConversation((prev: any) => [...prev, msg]);
      }
    }
  }

  // Get introduction from the server
  React.useEffect(() => {
    axios.get("/api/v1/introduction")
      .then((response) => {
        setIntroduction(response.data.text);
      })
      .catch((error) => {
        snackbarContext.setSeverity("error");
        snackbarContext.setMessage("Nie udało się pobrać danych użytkownika");
        snackbarContext.setOpen(true);
        console.error(error);
      });

    axios.get("/api/v1/available-sources")
      .then((response) => {
        setAvailableSources(response.data);
        setSelectedSources(Object.keys(response.data));
      })
      .catch((error) => {
        snackbarContext.setSeverity("error");
        snackbarContext.setMessage("Nie udało się pobrać dostępnych źródeł");
        snackbarContext.setOpen(true);
        console.error(error);
      });
  }, []);

  const regenerateMessage = React.useCallback(() => {
    // Remove newest message
    setConversation((prev: any) => prev.slice(0, -1));

    sendMessage(JSON.stringify([
      getIntroduction(),
      getDocumentMessage(),
      ...conversation.slice(0, -1)
    ]));
  }, [conversation]);

  React.useEffect(() => {
    if (lastMessage) {
      const data = JSON.parse(lastMessage.data);

      if (data.message === "generating") {
        setNewestMessage(data.answer);
        // ref.current.scrollIntoView(false, { behavior: "smooth" });
      }
      else if (data.message === "done") {
        setNewestMessage("");
        if (data.answer) {
          const msg = {
            "role": "gaius",
            "message": data.answer,
            "type": "conversation"
          }
          setConversation((prev: any) => [...prev, msg]);
        }
        setLoading(false);
      }
      else if (data.message === "function") {
        if (data.args === "") {
          setNewestMessage("Analizuję polecenie...");
          setExecuting(true);
        }
        else {
          const params = JSON.parse(data.args);
          params["sources"] = selectedSources;
          searchWS.sendMessage(JSON.stringify(params));
          setExecuting(true);
        }
      }
      else if (data.message === "answer_is_document") {
        setConversation((prev: any) => {
          const lastMessage = prev[prev.length - 1];
          lastMessage.type = "document";
          return [...prev];
        });
      }
      else if (data.message === "error") {
        setNewestMessage(data.error);
        setLoading(true);
        setExecuting(false);
      }
    }
  }, [lastMessage]);

  React.useEffect(() => {
    if (searchWS.lastMessage) {
      const data = JSON.parse(searchWS.lastMessage.data);

      if (data.message === "searching") {
        document.title = "⌛ " + document.title.replace("⌛ ", "");
        setNewestMessage("Przeglądam miliony dokumentów...");
      }
      else if (data.message === "finding_context") {
        setNewestMessage("Wyszukuję dodatkowe informacje ...");
      }
      else if (data.message === "summarizing") {
        setNewestMessage("Podsumowuję dokumenty...");
        setDocuments(data.documents);
      }
      else if (data.message === "generating") {
        document.title = document.title.replace("⌛ ", "");
        setNewestMessage("Analizuję wyniki...");
      }
      else if (data.message === "done") {
        document.title = document.title.replace("⌛ ", "");
        setExecuting(false);

        let metareview = data.metareview;

        // Add summaries to conversation context
        let summaries = "Dostępne źródła:\n";
        data.documents.map((document: any) => {
          const docSummary = document.summary;
          const docTitle = document["case_number"] ? document["case_number"] : document["article"];
          summaries += `Sygnatura: ${docTitle}\n\nStreszczenie: ${docSummary}\n\n`;
        });
        const summaryMsg = {
          "role": "human",
          "message": summaries,
          "hidden": true,
          "type": "conversation"
        }
        const msg = {
          "role": "search",
          "message": metareview,
          "type": "metareview",
          "hidden": false,
        }
        setConversation((prev: any) => [...prev, summaryMsg, msg]);

        msg.message = conversation[conversation.length - 1].message + "\n[TOOLS_FORBIDDEN]\n Skorzystałeś właśnie z narzędzi wyszukiwania, oto wynik który dostałeś. Czy masz coś do dodania?. \n```" + metareview + "```. Jeżeli wyniki mówią że nie udało się znaleźć odpowiedzi, napisz tylko że nie udało Ci się znaleźć odpowiedzi.";

        sendMessage(JSON.stringify([
          getIntroduction(),
          getDocumentMessage(),
          ...conversation,
          summaryMsg,
          msg,
        ]));
      }
    }
  }, [searchWS.lastMessage]);

  const humanMessages = conversation.filter((msg: any) => msg.role === "human" && !msg.hidden);
  const gaiusMessages = conversation.filter((msg: any) => msg.role === "gaius" && !msg.hidden);

  const lastHumanMessage = humanMessages[humanMessages.length - 1]?.message;
  const lastGaiusMessage = gaiusMessages[gaiusMessages.length - 1]?.message;

  return (
    <>
      <Helmet>
        <title>Porozmawiaj z Gaiusem | Gaius Lex - asystent prawnika</title>
        <meta name="description" content="Gaius Lex - asystent prawnika" />
      </Helmet>
      <div className="box">
        <h1 className={"heading__h2"}>Porozmawiaj z Gaiusem</h1>
        <p>Gaius pomoże Ci znaleźć orzeczenia pasujące do stanu faktycznego, przygotować strategię, rozplanować strukturę pisma, napisać dokument, oraz o wiele, wiele więcej</p>
        {/* examples */}
        {conversation.length == 0 && <div className="chat__intro">
          <img src={GaiusLexLogo} alt="gaius-lex-logo" />
          <h3>Twój Asystent Prawa</h3>
        </div>}

        <div className={"chat__wrapper"}>
          <div className="chat__message-container">
            {
              conversation.map((message: any, index: number) => {
                if (message.hidden)
                  return null;
                return (
                  <Message
                    key={index}
                    message={message}
                    isLastMessage={index === conversation.length - 1}
                    redo={regenerateMessage}
                  />
                )
              })
            }
            {newestMessage && <Message message={{ "role": "gaius", "message": newestMessage, "type": "conversation" }} />}
            {executing && (
              <Grid container spacing={2} mb={2}>
                <Grid item xs={12} sm={12}>
                  <LinearDeterminate quotes={true} />
                </Grid>
              </Grid>
            )}
            {documents.length > 0 && <DocumentTiles documents={documents} />}
            {!executing && conversation.length > 0 && <ThumbUpThumbDown question={lastHumanMessage} answer={lastGaiusMessage} />}
          </div>
          <SelectDocumentButton callback={setReferredDocument} />
          <Formik
            initialValues={{ message: "" }}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              setLoading(true);
              const msg = {
                "role": "human",
                "message": values.message
              }

              setConversation((prev: any) => [...prev, msg]);
              sendMessage(JSON.stringify([
                getIntroduction(),
                getDocumentMessage(),
                ...conversation,
                msg
              ]));
              resetForm();
              setSubmitting(false);
            }}
          >
            {({ values, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
              <form onSubmit={handleSubmit} className={"chat__form"}>
                <input
                  ref={ref}
                  id="message"
                  name="message"
                  value={values.message}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={"chat__form__input"}
                  disabled={isSubmitting || loading || executing}
                  placeholder="Napisz mi pismo, w którym wniesiesz o..."
                />
                {!(isSubmitting || loading || executing) && (
                  <button type="submit"
                    disabled={isSubmitting || loading || values.message === "" || executing}
                    className="chat__form__button">
                    Wyślij
                  </button>
                )
                }
                {
                  (isSubmitting || loading || executing) && (
                    <button
                      onClick={cancelExecution}
                      className="chat__form__button chat__form__button-cancel">
                      Przerwij
                    </button>
                  )
                }

              </form>
            )}
          </Formik>
          <Grid container spacing={2} direction="row" justifyContent="center" className="w-100">
            <Grid item xs={12} sm={12}>
              <Typography variant="h6" className="text-muted my-auto">Wybierz źródła:</Typography>
            </Grid>
            {Object.keys(availableSources).map((source: any) => {
              return (
                <Grid item xs={12} md={4} lg={2}>
                  <Button
                    key={source}
                    className="align-center w-100"
                    onClick={() => {
                      if (selectedSources.includes(source)) {
                        setSelectedSources(selectedSources.filter((s: any) => s !== source));
                      } else {
                        setSelectedSources([...selectedSources, source]);
                      }
                    }}
                    variant={selectedSources.includes(source) ? "contained-dark" : "outlined-light"}
                  >
                    <div className="w-100" style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div style={{ width: '20px', alignContent: 'center' }}>{selectedSources.includes(source) ? '✅' : '❌'}</div>
                      <div style={{ flexGrow: 1 }}>{availableSources[source]}</div>
                    </div>
                  </Button>
                </Grid>
              )
            })}
          </Grid>
        </div >

      </div>
      <Instructions /><br />
    </>
  );
}

export default ChatWithGPT;

const Instructions = () => {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleExpand = () => {
    // @ts-ignore
    setExpanded(!expanded);
  }

  return (
    <div className="box" style={{ marginTop: 24 }}>
      <div className={expanded ? "accordion__header accordion__header-active" : "accordion__header"}
        onClick={handleExpand}>
        <h2 className={"heading__h2"}>Wskazówki - Jak pracować z Gaius Lex?</h2>
        <svg xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512">
          <path
            d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
        </svg>
      </div>
      <div className={expanded ? "accordion__content accordion__content-active" : "accordion__content"}>
        <h3 className={"heading__h3"}>Przykłady pytań, które możesz zadać Gaiusowi</h3>
        <ul>
          <li>Napisz mi pismo, w którym wniesiesz o zasądzenie odszkodowania za wypadek komunikacyjny</li>
          <li>Jak rozstrzyga sędzia X w sprawach o zasiedzenie?</li>
          <li>Dlaczego Sąd Okręgowy w Nowym Sączu zdecydował się na odroczenie wykonania eksmisji w sprawie o
            sygnaturze III Ca 553/13?</li>
          <li><em>(Po dodaniu dokumentu)</em> Napisz mi pismo, w którym wniesiesz o zasądzenie odszkodowania za
            wypadek komunikacyjny, odnosząc się do załączonego dokumentu</li>
        </ul>
        <h3 className={"heading__h3"}>Dodawanie dokumentu</h3>
        <p>Jeśli masz dokument, który chcesz dołączyć do rozmowy, skorzystaj z pola "Wybierz dokument". Wpisz jego
          nazwę, a następnie wybierz z listy.<br />
          Gaius nie będzie w stanie wczytać dokumentów, które są za długie, lub które jeszcze się przetwarzają.<br />
          Aby dodać dokument do kolekcji, przejdź do zakładki <Link to="/browse">Własna Baza</Link></p>
        <h3 className={"heading__h3"}>Przeszukiwanie wszystkich dokumentów</h3>
        <p>Jeśli chcesz przeszukać swoje wszystkie dokumenty, poproś o to Gaiusa słowam "Odpowiedz na podstawie
          przeszukiwania moich dokumentów: ".</p>
        <h3 className={"heading__h3"}>Galeria przykładów</h3>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Image src={search} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Image src={pearls} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Image src={cars} />
          </Grid>
        </Grid>
      </div>


    </div>
  )
}

const DocumentTiles = ({ documents }: { documents: any }) => {
  const identifiers = [
    "case_number",
    "article",
    "judgement_id",
    "path",
    "id",
    "ELI",
    "display_name"
  ]

  return (
    <>
      <hr />
      <h4>Źródła</h4>
      <Grid container spacing={2} mb={2}>
        {documents.map((document: any) => {
          const id = identifiers.find((id: string) => document[id]);
          const docId = id ? document[id] : "Dokument";
          let url = "show-document?docId=" + docId + "&source=" + document.source;
          if (document.source === "KIO")
            url = "show-document?docId=" + document.file_name + "&source=" + document.source;

          if (id === "article")
            url = "";

          return (
            <Grid item xs={6} sm={6} md={4} lg={3}>
              <Tooltip title={document.summary}>
                <div>
                  <Button href={url} target="_blank" variant="outlined-light" sx={{ display: "block", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }} >
                    {id ? docId : "Dokument"}
                  </Button>
                </div>
              </Tooltip>
            </Grid>
          )
        })}
      </Grid>
      <hr />
    </>
  )
}

const ThumbUpThumbDown = ({ question, answer }: { question: string, answer: string }) => {
  const [sent, setSent] = React.useState<boolean>(false);

  const axios = useAxiosPrivate();

  const sendFeedback = async (score: number) => {
    setSent(true);
    await getCSRFToken();

    await axios.post("/api/v1/chat-feedback",
      JSON.stringify({
        question,
        answer,
        score
      }),
      {
        headers: {
          "Content-Type": "application/json"
        }
      },
    );
  }

  React.useEffect(() => {
    setSent(false);
  }, [question, answer]);

  return (
    <Box className="d-flex flex-column" sx={{
      justifyContent: "center",
      alignItems: "center",
      gap: 2,
      mt: 2,
      mb: 2
    }}>
      {sent ? null : <Typography variant="h6" sx={{ fontSize: "1rem" }} className="text-muted">Jak oceniasz odpowiedź Gaiusa?</Typography>}
      <Box className="d-flex flex-row" sx={{
        justifyContent: "center",
        alignItems: "center",
        gap: 2
      }}>
        {sent ? (
          <Typography variant="h6" sx={{ fontSize: "1rem" }} className="text-muted">Dziękujemy za przesłanie uwagi!</Typography>
        ) : (
          <>
            <img src={dissatisfied} alt="Nie jestem zadowolony" onClick={() => sendFeedback(0)} style={{ cursor: "pointer" }} />
            <img src={satisfied} alt="Jestem zadowolony" onClick={() => sendFeedback(1)} style={{ cursor: "pointer" }} />
          </>
        )}
      </Box>
    </Box>
  )
}

const PopUpImage = ({ src, alt, open, setOpen }: { src: string, alt?: string, open: boolean, setOpen: any }) => {
  const handleClose = () => {
    setOpen(false);
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
      <img src={src} alt={alt} />
    </Dialog>
  )
}

const Image = ({ src, alt }: { src: string, alt?: string }) => {
  const [open, setOpen] = React.useState<boolean>(false);

  return (
    <Box sx={{
      backgroundColor: "white",
      borderRadius: "0.75rem",
      padding: "20px",
      marginBottom: "20px",
      width: "fit-content"
    }}>
      <img width={256} src={src} alt={alt} onClick={() => setOpen(true)} style={{ cursor: "pointer" }} />
      <PopUpImage src={src} alt={alt} open={open} setOpen={setOpen} />
    </Box>
  )
}

export { SelectDocumentButton };