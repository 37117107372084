export const Colors = {
    NavyBlue: "#0e2451",
    DarkNavyBlue: "#17181A",
    Black: "#000000",
    Peach: "#FA6E68",
    DarkGrey: "#02253F",
    White: "#ffffff",
    LightBlue: "#EBF7FC",
    LightRed: "#F9C3C4",
    GaiusLexBlue: "#00223e",
    Red: "#FF0033"
};
