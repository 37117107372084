import '@mdxeditor/editor/style.css'
import './LocalizedTextEditor.scss'
import React from 'react'
import StarterKit from '@tiptap/starter-kit'
import { Editor } from '@tiptap/core'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import Heading from '@tiptap/extension-heading'
import { EditorContent, useEditor } from '@tiptap/react'

const TRANSLATIONS = {
  "toolbar.undo": "Cofnij",
  "toolbar.redo": "Ponów",
  "toolbar.bold": "Pogrubienie",
  "toolbar.removeBold": "Usuń pogrubienie",
  "toolbar.italic": "Kursywa",
  "toolbar.removeItalic": "Usuń kursywę",
  "toolbar.underline": "Podkreślenie",
  "toolbar.removeUnderline": "Usuń podkreślenie",
  "toolbar.heading": "Nagłówek",
  "toolbar.paragraph": "Akapit",
  "toolbar.quote": "Cytat",
  "toolbar.removeQuote": "Usuń cytat",
  "toolbar.bulletList": "Lista punktowa",
  "toolbar.orderedList": "Lista numerowana",
  "toolbar.removeList": "Usuń listę",
  "toolbar.blockTypes.placeholder": "Typ bloku",
  "toolbar.blockTypes.paragraph": "Akapit",
  "toolbar.blockTypes.heading": "Nagłówek {{level}}",
  "toolbar.blockTypes.quote": "Cytat",
  "toolbar.blockTypes.bulletList": "Lista punktowa",
  "toolbar.blockTypes.orderedList": "Lista numerowana",
}

const LocalizedTextEditor = (props: {
  markdown: string;
  onChange: (markdown: string) => void;
}) => {
  const editor = useEditor({
    extensions: [
      StarterKit
    ],
    content: '',
  })

  return <EditorContent editor={editor} />
}

// const MDXTag = (props: { id: string, text: string, onClick: (id: string) => void }) => {
//   return <span id={props.id} onClick={() => props.onClick(props.id)}>{props.text}</span>
// }


// const LocalizedTextEditor = React.forwardRef((props: React.ComponentProps<typeof MDXEditor> & { setRealm?: any }, ref: React.Ref<MDXEditorMethods>) => {
//   const { setRealm } = props;

//   React.useEffect(() => {
//     if (ref && typeof ref === 'object' && ref.current) {
//       ref.current.setMarkdown(props.markdown);
//     }
//   }, [props.markdown, ref]);

//   return (
//     <MDXEditor
//       ref={ref as React.RefObject<MDXEditorMethods>}

//       {...props}
//       translation={(key, defaultValue, interpolations) => {
//         const translation = TRANSLATIONS[key as keyof typeof TRANSLATIONS] || defaultValue
//         if (typeof translation === 'string' && translation.includes('{{')) {
//           return translation.replace('{{level}}', interpolations?.level?.toString() || '')
//         }
//         return translation
//       }}
//       contentEditableClassName='text-verify-editor'
//       plugins={[
//         headingsPlugin(),
//         listsPlugin(),
//         quotePlugin(),
//         thematicBreakPlugin(),
//         markdownShortcutPlugin(),
//         realmPlugin({
//           init: (realm) => {
//             if (setRealm) {
//               setRealm(realm);
//             }
//           }
//         })(),
//         toolbarPlugin({
//           toolbarContents: () => {
//             return (
//               <>
//                 <BlockTypeSelect />
//                 <UndoRedo />
//                 <BoldItalicUnderlineToggles />
//               </>
//             )
//           }
//         }),
//         ...(props.plugins || [])
//       ]}
//     />
//   )
// })

export default LocalizedTextEditor;